import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const BlogsCorporativosPage = () => {
    const data = useStaticQuery(graphql`
    query {
      blogsCorporativosImage1: file(relativePath: { eq: "images/sections/marketing-digital/blogs-corporativos/por-que-tu-empresa-necesita-blog-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      blogsCorporativosImage2: file(relativePath: { eq: "images/sections/marketing-digital/blogs-corporativos/como-sera-tu-blog-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Agencia Creación Blogs"
                description="Agencia de Marketing Digital en Barcelona experta en diseño de blogs corporativos, blogs profesionales y blogs personales en Wordpress."
            />

            <BannerPage
                title="Agencia creación blogs Barcelona"
                text="Creación de blogs corporativos, profesionales o personales"
                color="green"
            />

            <SectionTextImage
                title1="Por qué necesitas"
                title2="un blog?"
                text={<Fragment>
                    <ol class="text-left">
                        <li>El blog es una <strong>herramienta ideal</strong> para que tu empresa tenga una imagen fresca y dinámica en Internet.</li>
                        <li>Es algo que a <strong>Google le gusta</strong>, ayuda a posicionar una página a nivel SEO.</li>
                        <li>Porqué <strong>mejora la reputación online</strong> y confianza entre  empresa y usuario ya que se genera continuamente contenido explicando quién eres, qué ofreces, dudas de clientes, novedades, eventos, curiosidades, etc.</li>
                        <li>Son la <strong>fuente ideal para incorporar a tu plan estratégico</strong>.</li>
                    </ol>
                </Fragment>}
                image={data.blogsCorporativosImage1.childImageSharp.fluid}
                imageAlt="Por qué tener un blog - Agencia Creación Blogs"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="¿Cómo será "
                title2="tu blog?"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Blog <strong>personalizado</strong> y a medida</li>
                        <li><strong>Usable</strong>, rápido, <strong>claro</strong> y atractivo</li>
                        <li><strong>Seguro</strong> y fiable con certificados SSL</li>
                        <li><strong>Funcional y autogestionable</strong> mediante sistemas de gestión de contenidos (CMS) como Wordpress.</li>
                        <li><strong>Multi-idioma</strong></li>
                        <li><strong>Web Design Responsive</strong> (mobile, tablets &amp; desktop)</li>
                        <li><strong>Optimizado</strong> para el SEO (Google, Yahoo, Bing)</li>
                    </ul>
                </Fragment>}
                image={data.blogsCorporativosImage2.childImageSharp.fluid}
                imageAlt="Cómo será tu blog - Agencia Creación Blogs"
                color="green"
                containerTextRight={true}
            />

            <CallToAction
                color="green"
                title="¿Necesita tu empresa un blog?"
                text={<Fragment>
                    ¡En IBX somos expertos en la <strong>creación blogs</strong>!<br />
                    ¡Crea ese espacio que tanto gusta a tus clientes!
                </Fragment>}
                button="¡Contáctanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default BlogsCorporativosPage
